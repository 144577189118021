// Common
export const API = 'API';
export const DO_NOTHING = 'DO_NOTHING';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const TOGGLE_LOG_STATE = 'TOGGLE_LOG_STATE';

//Notifiers
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

// User Reducer Actions
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_USER_BANK_DETAILS = 'SET_USER_BANK_DETAILS';

export const SET_FILTER_MODAL_CONFIG = 'SET_FILTER_MODAL_CONFIG';
export const SET_BULK_RELEASE = 'SET_BULK_RELEASE';
export const SET_SELECTED_PAYMENTS = 'SET_SELECTED_PAYMENTS';
export const SET_SOURCE_SYSTEMS_LIST_DATA = 'SET_SOURCE_SYSTEMS_LIST_DATA';
export const SET_SOURCE_SYSTEMS_FILTER_LIST_DATA =
    'SET_SOURCE_SYSTEMS_FILTER_LIST_DATA';
export const SET_ALL_PAYMENT_INDENTS_LIST_DATA =
    'SET_ALL_PAYMENT_INDENTS_LIST_DATA';
export const SET_ALL_BENEFICIARIES_LIST_DATA =
    'SET_ALL_BENEFICIARIES_LIST_DATA';
export const SET_IGNORE_DATE_FILTER_FLAG = 'SET_IGNORE_DATE_FILTER_FLAG';

// Staff Reducer Action
export const SET_ALL_STAFF_INFO = 'SET_ALL_STAFF_INFO';
export const SET_ALL_STAFF_PAYSLIP = 'SET_ALL_STAFF_PAYSLIP';
export const SET_KYC_STAFF_INFO = 'SET_KYC_STAFF_INFO';
export const SET_STAFF_EV_INFO = 'SET_STAFF_EV_INFO';
export const SET_ALL_STAFF_EV_HISTORY = 'SET_ALL_STAFF_EV_HISTORY';
export const SET_HUB_LIST = 'SET_HUB_LIST';
export const SET_CLUSTER_LIST = 'SET_CLUSTER_LIST';
export const SET_STAFF_TYPE_LIST = 'SET_STAFF_TYPE_LIST';
export const UPDATE_STAFF_BANK_DETAILS = 'UPDATE_STAFF_BANK_DETAILS';
export const SET_SINGLE_STAFF_INFO = 'SET_SINGLE_STAFF_INFO';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';
export const SET_RATE_CARD = 'SET_RATE_CARD';
export const SET_SHIFT_TIMINGS_LIST = 'SET_SHIFT_TIMINGS_LIST';
export const SET_STAFF_CITY_ZONES = 'SET_STAFF_CITY_ZONES';
export const LEAD_SOURCE_STATIC_VALUES_DATA = 'LEAD_SOURCE_STATIC_VALUES_DATA';

// Attendance Reducer Actions
export const SET_ALL_ATTENDANCE_DATA = 'SET_ALL_ATTENDANCE_DATA';
export const SET_TIMELINE_ACTIVITY_DATA = 'SET_TIMELINE_ACTIVITY_DATA';
export const SET_PUNCH_IN_OUT_DATA = 'SET_PUNCH_IN_OUT_DATA';
export const APPROVE_PUNCH_IN_OUT_DATA = 'APPROVE_PUNCH_IN_OUT_DATA';
export const REASON_STATIC_VALUES_DATA = 'REASON_STATIC_VALUES_DATA';

// Home Reducer actions
export const SET_HOME_BASE_DATA = 'SET_HOME_BASE_DATA';
export const SET_DAILY_ATTENDACE_DATA = 'SET_DAILY_ATTENDACE_DATA';

// Modal Reducer actions
export const TOGGLE_ADD_EDIT_STAFF_MODAL = 'TOGGLE_ADD_EDIT_STAFF_MODAL';

// Payment Reducer actions
export const SET_ALL_PAYMENTS = 'SET_ALL_PAYMENTS';

// Report Reducer actions
export const SET_REPORT_DASHBOARD = 'SET_REPORT_DASHBOARD';
export const SET_REPORT_PAYMENTS = 'SET_REPORT_PAYMENTS';
export const SET_REPORT_EARNINGS = 'SET_REPORT_EARNINGS';
export const SET_REPORT_ATTENDENCE = 'SET_REPORT_ATTENDENCE';
export const SET_REPORT_EXPENSES = 'SET_REPORT_EXPENSES';
export const SET_REPORT_DISTANCE = 'SET_REPORT_DISTANCE';
export const SET_DISTANCE_REPORT_FROM_BEECON =
    'SET_DISTANCE_REPORT_FROM_BEECON';
export const SET_REPORT_PAYSLIP = 'SET_REPORT_PAYSLIP';

// Settings Reducer actions
export const SET_SETTINGS_HUBS = 'SET_SETTINGS_HUBS';
export const SET_SETTINGS_STATE = 'SET_SETTINGS_STATE';
export const SET_SETTINGS_CITIES = 'SET_SETTINGS_CITIES';
export const SET_SETTINGS_CITY_ZONES = 'SET_SETTINGS_CITY_ZONES';
export const SET_SETTINGS_BASIC_DATA = 'SET_SETTINGS_BASIC_DATA';
export const SET_SETTINGS_VENDORS_LIST = 'SET_SETTINGS_VENDORS_LIST';
export const SET_SETTINGS_CLIENTS_LIST = 'SET_SETTINGS_CLIENTS_LIST';
export const SET_SETTINGS_TELEMATICS_LIST = 'SET_SETTINGS_TELEMATICS_LIST';
export const SET_SETTINGS_EV_VEHICLES_LIST = 'SET_SETTINGS_EV_VEHICLES_LIST';
export const SET_SETTINGS_EV_VEHICLES_LIST_V2 =
    'SET_SETTINGS_EV_VEHICLES_LIST_V2';
export const SET_SETTINGS_EV_VENDOR_DATA = 'SET_SETTINGS_EV_VENDOR_DATA';
export const SET_SETTINGS_EV_CAPTAIN_DATA = 'SET_SETTINGS_EV_CAPTAIN_DATA';
export const SET_SETTINGS_EV_TEAM_DATA = 'SET_SETTINGS_EV_TEAM_DATA';
export const SET_SETTINGS_EV_PROJECTS_DATA = 'SET_SETTINGS_EV_PROJECTS_DATA';
export const SET_SETTINGS_EV_HISTORY_DATA = 'SET_SETTINGS_EV_HISTORY_DATA';
export const SET_SETTINGS_EV_UNTAG_REASONS_DATA =
    'SET_SETTINGS_EV_UNTAG_REASONS_DATA';
export const SET_SETTINGS_SHIFT_LIST = 'SET_SETTINGS_SHIFT_LIST';
export const SET_SETTINGS_SHIFT_USER_LIST = 'SET_SETTINGS_SHIFT_USER_LIST';
export const SET_PREFERRED_WORK_LOCATIONS = 'SET_PREFERRED_WORK_LOCATIONS';

// Map View / Tracking actions
export const SET_TRACKING_VEHICLES_LIST = 'SET_TRACKING_VEHICLES_LIST';
export const SET_TRACKING_DATA = 'SET_TRACKING_DATA';
export const SET_TRACKING_TOGGLE_EVENTS = 'SET_TRACKING_TOGGLE_EVENTS';
export const SET_MAPVIEW_ALL_STAFF = 'SET_MAPVIEW_ALL_STAFF';

// Hire Reducer Action
export const SET_ALL_LEAD_INFO = 'SET_ALL_LEAD_INFO';
export const UPDATE_LEAD_INFO = 'UPDATE_LEAD_INFO';

// Control Center Action
export const SET_FILTER_UPDATE = 'SET_FILTER_UPDATE';
export const GET_ZONES_LIST = 'GET_ZONES_LIST';
