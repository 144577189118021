import Swal from 'sweetalert2';
export const validateForm = (formData: any): boolean => {
    const {
        name,
        dob,
        vehicleNumber,
        vehicleTypeSelected,
        preferredLocationSelected,
        preferredZones,
        pincode,
        city,
        emergencyContactName,
        emergencyContactNumber,
    } = formData;
    console.log('validateForm', formData);
    // Name Validation
    // if (!name) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Invalid Name',
    //         text: 'Name required',
    //     });
    //     return false;
    // }

    // Date of Birth Validation
    // const dobDate = new Date(dob);
    // const age = new Date().getFullYear() - dobDate.getFullYear();
    // const is18OrAbove =
    //     dobDate <=
    //     new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    // if (!dob || !is18OrAbove) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Date of Birth Required',
    //         text: 'DOB must be at least 18 years old.',
    //     });
    //     return false;
    // }

    if (!vehicleTypeSelected?.id) {
        Swal.fire({
            icon: 'error',
            title: 'Vehicle Type Required',
            text: 'Please select a vehicle type.',
        });
        return false;
    }

    // Vehicle Number Validation (Example: TN09AB1234)
    const vehicleRegex = /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/;
    //|| !vehicleRegex?.test(vehicleNumber?.toUpperCase())
    if (
        !!vehicleTypeSelected?.id &&
        !!vehicleTypeSelected?.vehicleNumberMandatory &&
        !vehicleNumber
    ) {
        Swal.fire({
            icon: 'error',
            title: 'Vehicle Number Required',
            text: 'Please enter a valid Indian vehicle registration number (e.g., TN09AB1234).',
        });
        return false;
    }
    if (!preferredLocationSelected?.id) {
        Swal.fire({
            icon: 'error',
            title: 'Preferred Location Required',
            text: 'Please select a preferred location.',
        });
        return false;
    }
    if (!preferredZones?.length) {
        Swal.fire({
            icon: 'error',
            title: 'Preferred Zones Required',
            text: 'Please select at least one preferred zone.',
        });
        return false;
    }
    // if (!city?.id) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'City Required',
    //         text: 'Please enter a city.',
    //     });
    //     return false;
    // }
    // if (!pincode) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Pincode Required',
    //         text: 'Please enter a valid pincode.',
    //     });
    //     return false;
    // }
    // if (!emergencyContactName) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Emergency Contact Name Required',
    //         text: 'Please enter the name of your emergency contact.',
    //     });
    //     return false;
    // }
    // if (!emergencyContactNumber || emergencyContactNumber?.length != 10) {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'Emergency Contact Number Required',
    //         text: 'Please enter the valid number of your emergency contact.',
    //     });
    //     return false;
    // }

    return true;
};
