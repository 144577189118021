import * as constants from './../constants';
import { API_USER_SERVICE_PORT } from '../../config/env/env';
import { setUserInfo } from './authActionCreator';
import store from '../configureStore';
import {
    resetMapviewVehiclesData,
    resetMapviewAllStaffVehiclesData,
} from './mapViewActionCreator';

export const GetCities = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/cities/all`,
        method: 'GET',
        data,
        success: (response) => {
            console.log('GetCities', response.response);
            return {
                type: constants.SET_SETTINGS_CITIES,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetCityZones = (cityId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/${cityId}/zones`,
        method: 'GET',
        success: (response) => {
            console.log('GetCityZones', response.response);
            return {
                type: constants.SET_SETTINGS_CITY_ZONES,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetchZones = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/zones`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('GetchZoneList', response.response);
            return {
                type: constants.SET_SETTINGS_CITY_ZONES,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getWorkLocationZonesWithAssets = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/zones/v2`,
        port: API_USER_SERVICE_PORT,
        method: 'POST',
        data,
        success: (data) => {
            let response = {
                workLocationZones: [],
                zoneClusters: [],
            };
            // Response to have status 200 for all success cases
            if (data && data.status && data.status.code === 200) {
                if (data && data.response && data.response.zones) {
                    response = {
                        workLocationZones: data.response.zones,
                        singleZoneSelect:
                            data?.response?.singleZoneSelect ?? false,
                        isClusteredZones:
                            data?.response?.clusteredZones ?? false,
                        zoneClusters: data?.response?.zoneClusters ?? [],
                    };
                }
            }
            return {
                type: constants.GET_ZONES_LIST,
                payload: response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetStates = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/states`,
        method: 'GET',
        data,
        success: (response) => {
            console.log('GetStates', response.response);
            return {
                type: constants.SET_SETTINGS_STATE,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetCityByState = (stateId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/common/getcities?stateId=${stateId}`,
        method: 'GET',
        success: (response) => {
            console.log('GetStates', response.response);
            return {
                type: constants.SET_SETTINGS_CITIES,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const fetchPreferredWorkLocations = (leadId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/lead/${leadId}/preferred-work-locations`,
        method: 'POST',
        success: (response) => {
            console.log('SET_PREFERRED_WORK_LOCATIONS', response.response);
            return {
                type: constants.SET_PREFERRED_WORK_LOCATIONS,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetOrgs = (onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/orgs/all`,
        method: 'GET',
        success: (response) => {
            console.log('SET_ORGANIZATION', response.response);
            return {
                type: constants.SET_ORGANIZATION,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const ChangeOrg = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/orgs/${id}`,
        method: 'PUT',
        success: (response) => {
            console.log('changeOrg', response.response);
            const storeInfo = store?.getState()?.settings?.basicDetails ?? {};
            const storeUserInfo = store?.getState()?.user ?? {};
            const data = {
                status: response.status,
                response: {
                    token: response.response.token,
                    userBO: {
                        ...storeUserInfo,
                    },
                },
            };
            store.dispatch(setUserInfo(data));
            store.dispatch(
                StoreOrganisation({
                    ...storeInfo,
                    name: response.response.orgName,
                    id: response.response.orgId,
                }),
            );
            store.dispatch(resetMapviewVehiclesData());
            store.dispatch(resetMapviewAllStaffVehiclesData());
            return {
                type: constants.DO_NOTHING,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const GetDeliveryHubs = (data = {}, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/hubs/all`,
        method: 'POST',
        data,
        success: (response) => {
            console.log('GetPaymentReport', response.response);
            return {
                type: constants.SET_SETTINGS_HUBS,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const CreateHub = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/hubs/',
        method: 'POST',
        data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const EditHub = ({ ...data }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/hubs/' + data.hubs[0].id,
        method: 'PUT',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const DeleteHub = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/hubs/' + id,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const GetOrganisations = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/organisations/' + id,
        method: 'GET',
        success: (response) => {
            return {
                type: constants.SET_SETTINGS_BASIC_DATA,
                payload: response,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const SetOrganisations = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/organisations/',
        method: 'POST',
        data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const StoreOrganisation = (data) => ({
    type: constants.SET_SETTINGS_BASIC_DATA,
    payload: data,
});

// VENDOR APIs
export const createVendor = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/vendor',
        method: 'POST',
        data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editVendor = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/vendor',
        method: 'PUT',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const deleteVendor = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/vendor/' + id,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

// export const getAllVendorList = (onSuccess, onError) => ({
//     type: constants.API,
//     payload: {
//         url: '/org/vendor',
//         method: 'GET',
//         data: {},
//         success: (response) => {
//             return { type: constants.DO_NOTHING, payload: response };
//         },
//         postProcessSuccess: onSuccess,
//         postProcessError: onError,
//         port: API_USER_SERVICE_PORT,
//     },
// });

export const getAllVendorsDetails = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/vendor/all',
        method: 'GET',
        data,
        success: (response) => {
            return {
                type: constants.SET_SETTINGS_VENDORS_LIST,
                payload: response?.response?.vendors ?? [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

// CLIENT APIs
export const createCustomer = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/customer',
        method: 'POST',
        data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editCustomer = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/customer',
        method: 'PUT',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const deleteCustomer = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/customer/' + id,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const createEvVehicle = (payload, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/ev/create`,
        method: 'POST',
        data: payload,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editEvVehicle = ({ id, payload }, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/ev/${id}/update`,
        method: 'POST',
        data: payload,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const deleteEvVehicle = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/ev/${id}/delete`,
        method: 'POST',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const bulkUploadEvVehicles = (payload, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/ev/upload`,
        method: 'POST',
        data: payload,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const getAllCustomersDetails = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/customer/all',
        method: 'GET',
        data,
        success: (response) => {
            console.log('getAllCustomersDetailsresponseeee', response);
            return {
                type: constants.SET_SETTINGS_CLIENTS_LIST,
                payload: response?.response?.customers ?? [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAllEVVehicles = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/ev/all',
        method: 'POST',
        data,
        success: (response) => {
            return {
                type: constants.SET_SETTINGS_EV_VEHICLES_LIST,
                payload: response?.response ?? {
                    lineItems: [],
                    totalCount: 0,
                },
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAllEVVehiclesV2 = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/ev/all/v2',
        method: 'POST',
        data,
        success: (response) => {
            return {
                type: constants.SET_SETTINGS_EV_VEHICLES_LIST_V2,
                payload: response?.response ?? {
                    lineItems: [],
                    totalCount: 0,
                },
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const blockUnblockEV = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/ev/control',
        method: 'POST',
        data,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                payload: null,
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAllEVVendors = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/ev/vendors',
        method: 'GET',
        data,
        success: (response) => {
            let data = response?.response?.dropDownList;
            data?.map((item) => (item.label = item?.name));
            return {
                type: constants.SET_SETTINGS_EV_VENDOR_DATA,
                payload: data ?? [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getEVVendorLocations = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${data?.staffId}/vendor/${data?.vendorId}/location`,
        method: 'POST',
        data,
        success: (response) => {
            let data = response?.response?.dropDownList;
            data?.map((item) => (item.label = item?.name));
            return {
                type: null,
                payload: [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getEVListVendorLocations = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${data?.staffId}/vendor/${data?.vendorId}/cityVendor/${data?.cityVendorID}/ev`,
        method: 'POST',
        data,
        success: (response) => {
            let data = response?.response?.dropDownList;
            data?.map((item) => (item.label = item?.name));
            return {
                type: null,
                payload: [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAllEVCaptains = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/ev/captains',
        method: 'GET',
        data,
        success: (response) => {
            let data = response?.response?.dropDownList;
            data?.map((item) => (item.label = item?.name));
            return {
                type: constants.SET_SETTINGS_EV_CAPTAIN_DATA,
                payload: data ?? [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAllEVTelematics = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/ev/telematics',
        method: 'GET',
        data,
        success: (response) => {
            let data = response?.response?.dropDownList;
            data?.map((item) => (item.label = item?.name));
            return {
                type: constants.SET_SETTINGS_TELEMATICS_LIST,
                payload: data ?? [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAllEVTeams = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/ev/teams',
        method: 'GET',
        data,
        success: (response) => {
            let data = response?.response?.dropDownList;
            data?.map((item) => (item.label = item?.name));
            return {
                type: constants.SET_SETTINGS_EV_TEAM_DATA,
                payload: data ?? [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAllEVProjects = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/ev/projects',
        method: 'GET',
        data,
        success: (response) => {
            let data = response?.response?.dropDownList;
            data?.map((item) => (item.label = item?.name));
            return {
                type: constants.SET_SETTINGS_EV_PROJECTS_DATA,
                payload: data ?? [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getAllEVUntagReason = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/ev/untag/reasons',
        method: 'GET',
        data,
        success: (response) => {
            let data = response?.response?.dropDownList;
            data?.map((item) => (item.label = item?.name));
            return {
                type: constants.SET_SETTINGS_EV_UNTAG_REASONS_DATA,
                payload: data ?? [],
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getEvVehicleHistory = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/ev/${data?.id}/history`,
        method: 'POST',
        data,
        success: (response) => {
            return {
                type: constants.SET_SETTINGS_EV_HISTORY_DATA,
                payload: response?.evHistoryLineItems ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const getEvVehiclePerformance = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/ev/performance`,
        method: 'POST',
        data,
        success: (response) => {
            return {
                type: constants.DO_NOTHING,
                payload: {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

// Shift timing APIs
export const fetchShiftTimingList = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/shift/all',
        method: 'POST',
        data,
        success: (response) => {
            console.log('fetchShiftTimingList', response);
            return {
                type: constants.SET_SETTINGS_SHIFT_LIST,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const fetchShiftUsersList = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/shift/users/',
        method: 'POST',
        data,
        success: (response) => {
            console.log('fetchShiftUsersList', response);
            return {
                type: constants.SET_SETTINGS_SHIFT_USER_LIST,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const fetchShiftfilterList = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/shift/filter',
        method: 'POST',
        data,
        success: (response) => {
            console.log('fetchShiftfilterList', response);
            return {
                type: constants.SET_SETTINGS_SHIFT_LIST,
                payload: response?.response ?? {},
            };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const createShiftTiming = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/shift',
        method: 'POST',
        data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const editShiftTiming = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/shift/${data.id}`,
        method: 'PUT',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

// used this api for autocomplete drop down showing list of hubs
export const GetAllStaffList = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/list-all`,
        method: 'POST',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const GetShiftsByStaffHub = (staffid, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/shift/staff/${staffid}`,
        method: 'POST',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
export const AssignShiftToStaff = (staffId, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: `/org/staff/${staffId}/assign-shift`,
        method: 'POST',
        data: data,
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});

export const deleteShiftTiming = (id, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        url: '/org/shift/' + id,
        method: 'DELETE',
        success: (response) => {
            return { type: constants.DO_NOTHING, payload: response };
        },
        postProcessSuccess: onSuccess,
        postProcessError: onError,
        port: API_USER_SERVICE_PORT,
    },
});
