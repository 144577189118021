import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import Modal from './Modal';
import { FileUploader } from '../UI';
import { connect } from 'react-redux';
import { downloadData, showMessage } from '../../utils/helper';
import {
    bulkLeadUpload,
    bulkLeadUploadStatus,
} from '../../store/actions/leadActionCreator';
import { useDebounce } from '../../hooks/useDebounce';
// const useStyles = makeStyles((theme) => ({
//     modal: {},
// }));

function UploadLeadModal({
    open,
    onClose,
    enumType = 'payment',
    data = {},
    dispatchBulkLeadUpload,
    dispatchBulkLeadUploadStatus,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const { debounce, isBouncing } = useDebounce();

    const handleClose = () => {
        // Setting form data to null before closing
        setState(null);
        setStateStatus(null);

        toggleModal(!isOpen);
        onClose && onClose();
    };
    const [state, setState] = useState({});
    const [isSubmitDisabled, setSubmitDisabled] = useState(true);
    const [stateStatus, setStateStatus] = useState({
        staffName: {},
        phoneNumber: {},
        vehicleNumber: {},
    });

    useEffect(() => {
        setSubmitDisabled(!state?.s3FileUrl);
    }, [state?.s3FileUrl]);

    const isBulkUpload = enumType === 'bulkUpload';
    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disabled={isBouncing || isSubmitDisabled || !state?.s3FileUrl}
            disableElevation
            onClick={async () => {
                debounce(async () => {
                    if (!state?.s3FileUrl) {
                        showMessage('Please upload file', 'error');
                        return false;
                    }
                    await toggleLoading(true);
                    await setSubmitDisabled(true);
                    if (isBulkUpload) {
                        dispatchBulkLeadUploadStatus(
                            { fileName: state?.s3FileUrl },
                            (response) => {
                                toggleLoading(false);
                                setSubmitDisabled(false);
                                console.log('fileName response', response);
                                if (response?.response?.responseFile) {
                                    const file =
                                        response?.response?.responseFile;
                                    let fileName =
                                        response?.response?.responseFile.split(
                                            '/',
                                        );
                                    fileName = fileName[fileName?.length - 1];
                                    downloadData(file, fileName);
                                }
                                handleClose();
                            },
                            (resp) => {
                                toggleLoading(false);
                                setSubmitDisabled(false);
                            },
                        );
                    } else {
                        dispatchBulkLeadUpload(
                            { fileName: state?.s3FileUrl },
                            (response) => {
                                toggleLoading(false);
                                setSubmitDisabled(false);
                                console.log('s3FileUrl response', response);
                                handleClose();
                            },
                            (resp) => {
                                toggleLoading(false);
                                setSubmitDisabled(false);
                            },
                        );
                    }
                });
            }}
        >
            {isBulkUpload ? 'Bulk Upload' : 'Upload Leads'}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));

        setStateStatus(null); // formValidator(key, value);
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={isBulkUpload ? 'Bulk Upload' : 'Upload Leads'}
            actions={actions}
            loading={loading}
        >
            {isBulkUpload && (
                <div style={{ marginBottom: 10 }}>
                    <a
                        href={
                            'https://earn-de-docs.s3.ap-south-1.amazonaws.com/lead-bulk-update-status.xlsx'
                        }
                    >
                        Sample Document format link
                    </a>
                </div>
            )}
            <FileUploader
                // label={'Upload Support Document'}
                placeholder={'Upload Support Document'}
                onFileUploaded={(url) => {
                    console.log(url);
                    handleInputChange(url, 's3FileUrl');
                }}
                onRemoved={() => {
                    handleInputChange('', 's3FileUrl');
                }}
                isRequired={true}
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatchBulkLeadUpload: (data, onSuccess, onError) =>
        dispatch(bulkLeadUpload({ ...data }, onSuccess, onError)),
    dispatchBulkLeadUploadStatus: (data, onSuccess, onError) =>
        dispatch(bulkLeadUploadStatus({ ...data }, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadLeadModal);
