import {
    Box,
    Grid,
    makeStyles,
    IconButton,
    TextField,
    Typography,
    Chip,
    Checkbox,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import { CustomTextField } from '../../../components/Textfield';
import { FileUploader, LightBoxImage } from '../../../components/UI';
import { Autocomplete } from '@material-ui/lab';
import '../HireItem.style.scss';
import { CustomDatePicker } from '../../../components/DateTimePicker';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
type BasicInfoProps = {
    [key: string]: any;
};
const validationSchema = yup.object({
    name: yup.string().trim().required('Name cannot be empty'),
    vehicleNumber: yup
        .string()
        .trim()
        .required('Vehicle Number cannot be empty'),
    address: yup.string().trim().required('address cannot be empty'),
    cityId: yup.string().trim().required('city cannot be empty'),
    state: yup.string().trim().required('state cannot be empty'),
    pincode: yup.string().trim().required('pincode cannot be empty'),
    contactNumber: yup
        .string()
        .min(10, 'Must be exactly 10 digits')
        .max(10, 'Must be exactly 10 digits')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .nullable(),
});

const useStyles = makeStyles({
    root: {},
    disabled: {
        marginTop: '8px',
        backgroundColor: '#ebebeb',
        color: '#263238',
        marginBottom: '16px',
        borderRadius: 8,
    },
});
// BasicInfo component with formik and yup validation
const BasicInfoV2: React.FC<BasicInfoProps> = ({
    leadInfo,
    toggleLoading,
    dispatchFetchBasicLeadProfile,
    cityList,
    onSubmit,
    onValueChange,
    dispatchGetWorkLocationZonesWithAssets,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { user, mapview, staff, settings } = useSelector(
        (store: DefaultRootState | any) => store || {},
    );
    const staticData: any = user?.config ?? {};
    const riderVehicleTypes = staticData?.riderVehicleTypes ?? [];
    const riderDeliveryPreference = staticData?.riderDeliveryPreference ?? [];

    const formik: any = useFormik({
        initialValues: {
            name: leadInfo?.name ?? '',
            contactNumber: leadInfo?.contactNumber ?? '',
            dob: leadInfo.dob
                ? moment(leadInfo?.dob)?.format('DD-MMM-YYYY')
                : null,
            vehicleNumber: leadInfo?.vehicleNumber ?? '',
            vehicleType: leadInfo?.vehicleType ?? '',
            address: leadInfo?.address ?? '',
            city: leadInfo?.city ?? '',
            state: leadInfo?.state ?? '',
            pincode: leadInfo?.pincode !== 0 ? leadInfo?.pincode : '',
            preferredLocation:
                leadInfo.cityName ?? leadInfo?.preferredLocation ?? '',
            preferredZones: [],
            emergencyContactName: leadInfo.emergencyContactName ?? '',
            emergencyContactNumber: leadInfo.emergencyContactNumber ?? '',
            isAssetServiceable: leadInfo.isAssetServiceable ?? '',
            isCityServiceable: leadInfo.isCityServiceable ?? '',
            isPreferredZonesServiceable:
                leadInfo.isPreferredZonesServiceable ?? '',
            ...leadInfo,
        },
        validationSchema: validationSchema,
        onSubmit: async (values: any) => {},
    });

    //states value
    const [basicInfo, setBasicInfo] = useState<any>({});
    const [filteredCities, setFilteredCities] = useState<any>({});
    const [workLocationZonesOptions, setWorkLocationZonesOptions] =
        useState<any>([]);

    const fetchLeadProfile = () => {
        toggleLoading(true);
        dispatchFetchBasicLeadProfile(
            leadInfo?.id,
            {},
            (resp: any) => {
                const values = resp.response;

                const newValues = {
                    ...values,
                    name: values?.name ?? '',
                    contactNumber: values?.contactNumber ?? '',
                    dob: values?.dob ? moment(values?.dob) : null,
                    vehicleNumber: values?.vehicleNumber ?? '',
                    vehicleType:
                        riderVehicleTypes?.find(
                            (c: any) => c.id === values?.vehicleType,
                        ) ?? '',
                    vehicleTypeId: values?.vehicleType,
                    address: values?.address ?? '',
                    cityId: values.cityId ?? '',
                    preferredLocationId: values?.cityId ?? '',
                    city:
                        cityList?.find((c: any) => c.id === values.cityId) ??
                        null,
                    state:
                        cityList?.find((c: any) => c.id === values.cityId)
                            ?.stateName ?? '',
                    pincode: values?.pincode !== 0 ? values?.pincode : '',
                    s3ProfileLink: values?.s3ProfileLink ?? '',
                    emergencyContactName: values.emergencyContactName ?? '',
                    emergencyContactNumber: values.emergencyContactNumber ?? '',
                    secondaryContactNumber: values.secondaryContactNumber ?? '',
                    preferredLocation:
                        cityList?.find((c: any) => c.id === values.cityId) ??
                        null,
                    preferredZones: !!values?.preferredZones?.length
                        ? values?.preferredZones?.map((item: any) => {
                              return {
                                  ...item,
                                  id: item?.id,
                                  name: item?.zoneName,
                                  val: true,
                              };
                          })
                        : [],
                    isAssetServiceable: values.isAssetServiceable ?? '',
                    isCityServiceable: values.isCityServiceable ?? '',
                    isPreferredZonesServiceable:
                        values.isPreferredZonesServiceable ?? '',
                };
                console.log(newValues);
                setBasicInfo(newValues);
                formik.setValues(newValues, true);

                toggleLoading(false);
            },
            (err: any) => {
                toggleLoading(false);
            },
        );
    };

    useEffect(() => {
        fetchLeadProfile();
    }, []);

    useEffect(() => {
        let zonesPayload: any = [];
        formik.values?.preferredZones?.map((zone: any, index: number) => {
            if (zone?.id)
                zonesPayload.push({
                    id: zone?.id,
                    zoneName: zone?.name,
                });
        });
        onValueChange &&
            onValueChange({
                ...formik.values,
                preferredZones: zonesPayload,
                vehicleTypeSelected: formik.values?.vehicleType ?? {},
                preferredLocationSelected:
                    formik?.values?.preferredLocation ?? {},
                vehicleType: formik.values?.vehicleType?.id ?? '',
                dob: formik.values?.dob
                    ? moment(formik.values?.dob).format('DD-MMM-yyyy')
                    : null,
                cityId:
                    formik?.values?.preferredLocation?.id ??
                    formik.values?.cityId,
                preferredLocation:
                    formik?.values?.preferredLocation?.name ?? '',
            });
    }, [basicInfo, formik.values]);

    const [stateStatus, setStateStatus] = useState<any>({
        vehicleType: {},
        preferredLocation: {},
        preferredZones: {},
    });
    const formValidator = (key: any, value: any) => {
        if (value !== undefined && !value) {
            console.log(value);
            if (key === 'vehicleType') {
                if (!value) {
                    setStateStatus((prev: any) => ({
                        ...prev,
                        vehicleType: {
                            status: true,
                            error: 'Vechicle Type Not Serviceable',
                        },
                    }));
                } else {
                    setStateStatus((prev: any) => ({
                        ...prev,
                        vehicleType: {},
                    }));
                }
            } else if (key === 'preferredLocation') {
                if (!value) {
                    setStateStatus((prev: any) => ({
                        ...prev,
                        preferredLocation: {
                            status: true,
                            error: 'Location Not Serviceable',
                        },
                    }));
                } else {
                    setStateStatus((prev: any) => ({
                        ...prev,
                        preferredLocation: {},
                    }));
                }
            } else if (key === 'preferredZones') {
                if (!value) {
                    setStateStatus((prev: any) => ({
                        ...prev,
                        preferredZones: {
                            status: true,
                            error: 'Preferred Zones Not Serviceable',
                        },
                    }));
                } else {
                    setStateStatus((prev: any) => ({
                        ...prev,
                        preferredZones: {},
                    }));
                }
            }
        }
    };

    useEffect(() => {
        formValidator('vehicleType', basicInfo?.isAssetServiceable);
        formValidator('preferredLocation', basicInfo?.isCityServiceable);
        formValidator('preferredZones', basicInfo?.isPreferredZonesServiceable);
    }, [basicInfo]);
    // Track the previous form values
    const previousValues = useRef(formik.initialValues);

    useEffect(() => {
        // Compare current form values with previous ones
        const currentValues = formik?.values;
        const changes: Record<string, { oldValue: any; newValue: any }> = {};

        Object.keys(currentValues).forEach((key) => {
            if (currentValues?.[key] !== previousValues?.current?.[key]) {
                changes[key] = {
                    oldValue: previousValues.current?.[key],
                    newValue: currentValues?.[key],
                };
            }
        });

        if (Object.keys(changes).length > 0) {
            console.log('Form changes detected:', changes);

            // Optionally call an API with the changes
            // API_CALL(changes);
            if (
                !!changes?.['vehicleTypeId'] ||
                !!changes?.['preferredLocationId']
            ) {
                // Trigger the API call
                if (
                    formik?.values?.vehicleTypeId &&
                    formik?.values?.preferredLocationId
                ) {
                    dispatchGetWorkLocationZonesWithAssets(
                        {
                            assetId: formik?.values?.vehicleTypeId,
                            cityId: formik?.values?.preferredLocationId,
                        },
                        (resp: any) => {},
                        (err: any) => {},
                    );
                }
            }
        }

        // Update the reference to the current values
        previousValues.current = currentValues;
    }, [formik.values]);

    React.useEffect(() => {
        const temp: any = [];
        if (settings?.isClusteredZones) {
            settings?.zoneClusters?.forEach((eachCluster: any) => {
                eachCluster?.zones?.map((zone: any, index: number) => {
                    temp.push({
                        groupTitle: eachCluster.clusterName,
                        isSelectedOptions: eachCluster?.isSelectedOptions,
                        id: zone?.id,
                        name: zone?.zoneName,
                        zoneName: zone?.zoneName,
                        val: false,
                    });
                });
            });
        } else {
            settings?.workLocationZones?.forEach((item: any) => {
                temp.push({
                    groupTitle: '',
                    isSelectedOptions: true,
                    id: item?.id,
                    name: item?.zoneName,
                    zoneName: item?.zoneName,
                    val: false,
                });
            });
        }
        setWorkLocationZonesOptions(temp);
        if (
            basicInfo?.cityId &&
            basicInfo?.preferredZones &&
            basicInfo?.cityId === basicInfo?.preferredLocation?.id
        ) {
            const temp: any = [];
            basicInfo?.preferredZones?.forEach((item: any) => {
                temp.push({
                    id: item?.id,
                    name: item?.zoneName,
                    val: true,
                });
            });
            /*formik.setValues({
                ...formik.values,
                preferredZones: temp,
            });*/
        }
    }, [
        settings?.workLocationZones,
        settings?.zoneClusters,
        settings?.isClusteredZones,
    ]);
    React.useEffect(() => {
        const temp: any = [];
        if (cityList.length) {
            cityList?.forEach((item: any) => {
                temp.push({
                    id: item?.id,
                    name: item?.name,
                    stateId: item?.stateId,
                    description: item?.stateName,
                    isPopular: item?.isPopular,
                    label: item?.isPopular ? 'Popular Cities' : 'Other Cities',
                });
            });
            setFilteredCities(temp);
        }
    }, [cityList]);
    const KYCDocuments: any = {
        AADHAR: 'Aadhar',
        AADHAR_UIDAI: 'Uidai Aadhar',
        PANCARD: 'Pan Card',
        RC: 'Vehicle RC',
        DRIVING_LICENSE: 'DL',
    };
    console.log('the forms', formik?.values, leadInfo);
    return (
        <Box className="basic-info" pt={'20px'}>
            <form onSubmit={formik.onSubmit}>
                <Grid container spacing={1}>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CustomTextField
                            fullWidth={true}
                            label="Name"
                            variant="outlined"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.name
                                    ? Boolean(formik.errors.name)
                                    : false
                            }
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                            style={{ marginBottom: '16px' }}
                            isRequired={true}
                        />
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <CustomTextField
                                fullWidth={true}
                                label="Phone Number"
                                variant="outlined"
                                id="contactNumber"
                                name="contactNumber"
                                value={formik.values.contactNumber}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.contactNumber
                                        ? Boolean(formik.errors.contactNumber)
                                        : false
                                }
                                helperText={
                                    formik.touched.contactNumber &&
                                    formik.errors.contactNumber
                                }
                                style={{ marginBottom: '16px', width: '48%' }}
                                isRequired={true}
                                disabled={true}
                            />
                            <CustomDatePicker
                                label="Date of Birth"
                                format="dd-MMM-yyyy"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                value={formik.values.dob}
                                onChange={(e: any) => {
                                    formik.setValues({
                                        ...formik.values,
                                        dob:
                                            moment(e)?.valueOf() > 0
                                                ? moment(e)?.format(
                                                      'DD-MMM-YYYY',
                                                  )
                                                : null,
                                    });
                                }}
                                error={
                                    formik.touched.dob
                                        ? Boolean(formik.errors.dob)
                                        : false
                                }
                                helperText={
                                    formik.touched.dob && formik.errors.dob
                                }
                                showInput={true}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            style={{
                                                padding: 0,
                                            }}
                                        >
                                            <InsertInvitationIcon />
                                        </IconButton>
                                    ),
                                }}
                                style={{ marginBottom: '16px', width: '48%' }}
                            />
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: formik?.values?.vehicleType
                                        ?.vehicleNumberMandatory
                                        ? '48%'
                                        : '100%',
                                    marginBottom: '16px',
                                }}
                            >
                                {/* <CustomTextField
                                    fullWidth={true}
                                    label="Vehicle Type"
                                    variant="outlined"
                                    id="vehicleType"
                                    name="vehicleType"
                                    value={formik.values.vehicleType}
                                    onChange={formik.handleChange}
                                    error={stateStatus?.vehicleType?.status}
                                    //helperText={stateStatus?.vehicleType?.error}
                                    className={classes.disabled}
                                    style={{
                                        marginBottom: '0px',
                                        width: '100%',
                                    }}
                                    isRequired={true}
                                    disabled={true}
                                />
                                {stateStatus?.vehicleType?.status && (
                                    <label
                                        style={{
                                            fontSize: '0.75rem',
                                            color: '#f44336',
                                            marginLeft: '14px',
                                            marginRight: '14px',
                                            backgroundColor: '#ffffff',
                                        }}
                                    >
                                        {stateStatus?.vehicleType?.error}
                                    </label>
                                )} */}
                                <Autocomplete
                                    options={riderVehicleTypes}
                                    value={formik?.values?.vehicleType}
                                    defaultValue={riderVehicleTypes.find(
                                        (c: any) =>
                                            c?.id ===
                                            formik?.values?.vehicleType?.id,
                                    )}
                                    getOptionLabel={(option) => option?.value}
                                    // onChange={formik.handleChange}
                                    onChange={(e, value) => {
                                        formik.setValues({
                                            ...formik.values,
                                            vehicleType: value,
                                            vehicleTypeId: value?.id,
                                            preferredZones: [],
                                        });
                                        // formik.handleChange({
                                        //     ...e,
                                        //     target: {
                                        //         ...e.target,
                                        //         name: 'cityId',
                                        //         value: value.cityId,
                                        //     },
                                        // });
                                    }}
                                    id="vehicleType"
                                    getOptionSelected={(option) => {
                                        return !!riderVehicleTypes?.find(
                                            (i: any) => i?.id === option?.id,
                                        );
                                    }}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <CustomTextField
                                            {...params}
                                            fullWidth={true}
                                            id="vehicleType"
                                            name="vehicleType"
                                            label="Vehicle Type"
                                            variant="outlined"
                                            isRequired={true}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-city',
                                            }}
                                            error={
                                                formik.touched.vehicleType
                                                    ? Boolean(
                                                          formik.errors
                                                              .vehicleType,
                                                      )
                                                    : false
                                            }
                                            helperText={
                                                formik.touched.vehicleType &&
                                                formik.errors.vehicleType
                                            }
                                        />
                                    )}
                                />
                            </Box>
                            {formik?.values?.vehicleType
                                ?.vehicleNumberMandatory && (
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '48%',
                                    }}
                                >
                                    <CustomTextField
                                        fullWidth={true}
                                        label="Vehicle Number"
                                        variant="outlined"
                                        id="vehicleNumber"
                                        name="vehicleNumber"
                                        value={formik.values.vehicleNumber}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.vehicleNumber
                                                ? Boolean(
                                                      formik.errors
                                                          .vehicleNumber,
                                                  )
                                                : false
                                        }
                                        helperText={
                                            formik.touched.vehicleNumber &&
                                            formik.errors.vehicleNumber
                                        }
                                        style={{
                                            marginTop: '8px',
                                            width: '100%',
                                        }}
                                        isRequired={true}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginBottom: '16px',
                            }}
                        >
                            {/* <CustomTextField
                                fullWidth={true}
                                label="Selected City"
                                variant="outlined"
                                id="preferredLocation"
                                name="preferredLocation"
                                value={formik.values.preferredLocation}
                                onChange={formik.handleChange}
                                error={stateStatus?.preferredLocation?.status}
                                //helperText={stateStatus?.preferredLocation?.error}
                                style={{
                                    marginTop: '8px',
                                    backgroundColor: '#ebebeb',
                                    color: '#263238',
                                    marginBottom: '0px',
                                    borderRadius: 8,
                                }}
                                labelStyle={{ color: '#263238' }}
                                isRequired={true}
                                disabled={true}
                            /> */}
                            <Autocomplete
                                options={cityList}
                                value={formik?.values?.preferredLocation}
                                defaultValue={cityList.find(
                                    (c: any) =>
                                        c.id ===
                                        formik?.values?.preferredLocation?.id,
                                )}
                                groupBy={(option) => option?.isPopular}
                                getOptionLabel={(option) => option?.name}
                                // onChange={formik.handleChange}
                                onChange={(e, value) => {
                                    formik.setValues({
                                        ...formik.values,
                                        cityId: value?.id,
                                        state: value?.stateName,
                                        preferredLocation: value,
                                        preferredLocationId: value?.id,
                                        preferredZones: [],
                                    });
                                    // formik.handleChange({
                                    //     ...e,
                                    //     target: {
                                    //         ...e.target,
                                    //         name: 'cityId',
                                    //         value: value.cityId,
                                    //     },
                                    // });
                                }}
                                id="preferredLocation"
                                getOptionSelected={(option) => {
                                    return !!cityList?.find(
                                        (i: any) => i?.id === option?.id,
                                    );
                                }}
                                style={{ width: '100%' }}
                                renderInput={(params) => (
                                    <CustomTextField
                                        {...params}
                                        fullWidth={true}
                                        id="preferredLocation"
                                        name="preferredLocation"
                                        label="Work City"
                                        variant="outlined"
                                        isRequired={true}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'work-city',
                                        }}
                                        error={
                                            formik.touched.preferredLocation
                                                ? Boolean(
                                                      formik.errors
                                                          .preferredLocation,
                                                  )
                                                : false
                                        }
                                        helperText={
                                            formik.touched.preferredLocation &&
                                            formik.errors.preferredLocation
                                        }
                                    />
                                )}
                            />
                            {stateStatus?.preferredLocation?.status && (
                                <label
                                    style={{
                                        fontSize: '0.75rem',
                                        color: '#f44336',
                                        marginLeft: '14px',
                                        marginRight: '14px',
                                        backgroundColor: '#ffffff',
                                    }}
                                >
                                    {stateStatus?.preferredLocation?.error}
                                </label>
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {!!basicInfo?.s3ProfileLink ? (
                            <Box
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    width: '100%',
                                    height: '240px',
                                    borderRadius: 16,
                                }}
                            >
                                <LightBoxImage
                                    imageStyles={{
                                        height: '240px',
                                        objectFit: 'contain',
                                        padding: 0,
                                        margin: 0,
                                    }}
                                    source={basicInfo?.s3ProfileLink}
                                />
                                <Box
                                    style={
                                        leadInfo?.faceMatchConfidence > 80
                                            ? {
                                                  padding: 0,
                                                  margin: 0,
                                                  backgroundColor: '#eaf6ea',
                                                  borderBottomRightRadius: 16,
                                                  borderBottomLeftRadius: 16,
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  justifyContent:
                                                      'space-around',
                                              }
                                            : leadInfo?.faceMatchConfidence > 50
                                            ? {
                                                  padding: 0,
                                                  margin: 0,
                                                  backgroundColor: '#FFDACC',
                                                  borderBottomRightRadius: 16,
                                                  borderBottomLeftRadius: 16,
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                              }
                                            : {
                                                  padding: 0,
                                                  margin: 0,
                                                  backgroundColor: '#ffcfcc',
                                                  borderBottomRightRadius: 16,
                                                  borderBottomLeftRadius: 16,
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                              }
                                    }
                                >
                                    {Object?.keys(
                                        basicInfo?.confidenceMap,
                                    )?.map((info) => (
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: 14,
                                                    padding: '5px 8px',
                                                    color: '#525050',
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {`${KYCDocuments?.[info]}: `}
                                            </Typography>

                                            <Typography
                                                style={
                                                    leadInfo?.faceMatchConfidence >
                                                    80
                                                        ? {
                                                              fontSize: 14,
                                                              color: '#4caf50',
                                                              fontWeight: 700,
                                                          }
                                                        : leadInfo?.faceMatchConfidence >
                                                          50
                                                        ? {
                                                              fontSize: 14,
                                                              color: '#FF6C36',
                                                              fontWeight: 700,
                                                          }
                                                        : leadInfo?.faceMatchConfidence >
                                                          0
                                                        ? {
                                                              fontSize: 14,
                                                              color: '#f61100',
                                                              fontWeight: 700,
                                                          }
                                                        : {
                                                              fontSize: 14,
                                                              color: '#14112d',
                                                              fontWeight: 700,
                                                          }
                                                }
                                            >
                                                {basicInfo?.confidenceMap?.[
                                                    info
                                                ]
                                                    ? `${basicInfo?.confidenceMap?.[info]}%`
                                                    : 'NA'}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        ) : (
                            <Box
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    width: '100%',
                                    height: '240px',
                                    borderRadius: 16,
                                }}
                            >
                                <FileUploader
                                    label={''}
                                    defaultValue={basicInfo?.s3ProfileLink}
                                    placeholder={'Upload Profile'}
                                    onFileUploaded={(url: any) => {
                                        console.log(url);
                                        // handleInputChange(url, 's3FileUrl');
                                        formik.setFieldValue(
                                            's3ProfileLink',
                                            url,
                                        );
                                    }}
                                    isRequired={true}
                                />
                            </Box>
                        )}
                        {/* {!!basicInfo?.s3ProfileLink ? (
                            <LightBoxImage
                                containerStyles={{
                                    width: '100%',
                                    height: '100%',
                                    marginRight: 5,
                                }}
                                imageStyles={{
                                    width: '100%',
                                    height: '272px',
                                    margin: 'auto',
                                    borderRadius: '16px',
                                    objectFit: 'contain',
                                }}
                                source={basicInfo?.s3ProfileLink}
                            />
                        ) : (
                            <></>
                        )} */}
                    </Grid>
                    {
                        <Grid item xs={12}>
                            {/* <CustomTextField
                                    fullWidth={true}
                                    // multiline={true}
                                    // rows={2}
                                    label="Preferred work Location"
                                    variant="outlined"
                                    id="preferredZones"
                                    name="preferredZones"
                                    value={
                                        formik.values?.preferredZones
                                            ?.map((i: any) => i?.zoneName ?? '')
                                            ?.join(',') ?? ''
                                    }
                                    onChange={formik.handleChange}
                                    error={stateStatus?.preferredZones?.status}
                                    //helperText={stateStatus?.preferredZones?.error}
                                    // style={{ marginBottom: '16px' }}
                                    style={{
                                        marginTop: '8px',
                                        backgroundColor: '#ebebeb',
                                        color: '#263238',
                                        borderRadius: 8,
                                    }}
                                    labelStyle={{ color: '#263238' }}
                                    isRequired={true}
                                    disabled={true}
                                /> */}
                            {!!formik?.values?.vehicleType?.id &&
                                !!formik?.values?.preferredLocation?.id &&
                                !!workLocationZonesOptions?.length && (
                                    <>
                                        <Autocomplete
                                            options={workLocationZonesOptions}
                                            multiple={true}
                                            value={
                                                formik?.values
                                                    ?.preferredZones ?? []
                                            }
                                            /*defaultValue={
                                                formik?.values
                                                    ?.preferredZones ?? []
                                            }*/
                                            groupBy={(option) =>
                                                option?.groupTitle
                                            }
                                            getOptionLabel={(option: any) =>
                                                option?.name
                                            }
                                            onChange={(e, value) => {
                                                formik.setValues({
                                                    ...formik.values,
                                                    preferredZones: value,
                                                });
                                            }}
                                            id="preferredZones"
                                            renderOption={(
                                                option,
                                                { selected },
                                            ) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={
                                                            checkedIcon
                                                        }
                                                        style={{
                                                            marginRight: 8,
                                                        }}
                                                        checked={selected}
                                                    />
                                                    {option?.name}
                                                </React.Fragment>
                                            )}
                                            getOptionSelected={(option) => {
                                                return !!workLocationZonesOptions?.find(
                                                    (i: any) =>
                                                        i?.id === option?.id,
                                                );
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value?.map((option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        label={option?.name}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                    />
                                                ))
                                            }
                                            style={{ width: '100%' }}
                                            renderInput={(params) => (
                                                <CustomTextField
                                                    {...params}
                                                    fullWidth={true}
                                                    id="preferredZones"
                                                    name="preferredZones"
                                                    label="Preferred work Location"
                                                    variant="outlined"
                                                    isRequired={true}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete:
                                                            'preferredZones',
                                                    }}
                                                    filterSelectedOptions
                                                    error={
                                                        formik.touched
                                                            .preferredLocation
                                                            ? Boolean(
                                                                  formik.errors
                                                                      .preferredLocation,
                                                              )
                                                            : false
                                                    }
                                                    helperText={
                                                        formik.touched
                                                            .preferredLocation &&
                                                        formik.errors
                                                            .preferredLocation
                                                    }
                                                />
                                            )}
                                        />
                                        {stateStatus?.preferredZones
                                            ?.status && (
                                            <label
                                                style={{
                                                    fontSize: '0.75rem',
                                                    color: '#f44336',
                                                    marginLeft: '14px',
                                                    marginRight: '14px',
                                                    backgroundColor: '#ffffff',
                                                }}
                                            >
                                                {
                                                    stateStatus?.preferredZones
                                                        ?.error
                                                }
                                            </label>
                                        )}
                                    </>
                                )}

                            {(leadInfo?.status === 'Onboarded' ||
                                leadInfo?.status === 'Converted') && (
                                <CustomTextField
                                    fullWidth={true}
                                    // multiline={true}
                                    // rows={2}
                                    label="Tagged Location"
                                    variant="outlined"
                                    id="taggedLocation"
                                    name="taggedLocation"
                                    value={
                                        formik.values?.taggedLocation
                                            ?.map((i: any) => i?.zoneName ?? '')
                                            ?.join(',') ?? ''
                                    }
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.taggedLocation
                                            ? Boolean(
                                                  formik.errors.taggedLocation,
                                              )
                                            : false
                                    }
                                    helperText={
                                        formik.touched.taggedLocation &&
                                        formik.errors.taggedLocation
                                    }
                                    // style={{ marginBottom: '16px' }}
                                    style={{
                                        marginTop: '8px',
                                        backgroundColor: '#ebebeb',
                                        color: '#263238',
                                        borderRadius: 8,
                                    }}
                                    labelStyle={{ color: '#263238' }}
                                    isRequired={true}
                                    disabled={true}
                                />
                            )}
                            <Typography
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    textAlign: 'left',
                                    color: '#14112d',
                                    marginTop: 12,
                                }}
                                variant="body2"
                            >
                                Address Details
                            </Typography>
                        </Grid>
                    }
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CustomTextField
                            multiline={true}
                            rows={3}
                            fullWidth={true}
                            label="Address"
                            variant="outlined"
                            id="address"
                            name="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.address
                                    ? Boolean(formik.errors.address)
                                    : false
                            }
                            helperText={
                                formik.touched.address && formik.errors.address
                            }
                        />
                    </Grid>
                    {/* <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Autocomplete
                            options={cityList}
                            value={formik.values.city}
                            defaultValue={cityList.find(
                                (c: any) => c.id === formik.values.cityId,
                            )}
                            getOptionLabel={(option) => option.name}
                            // onChange={formik.handleChange}
                            onChange={(e, value) => {
                                formik.setValues({
                                    ...formik.values,
                                    city: value,
                                    cityId: value.id,
                                    state: value.stateName,
                                });
                                // formik.handleChange({
                                //     ...e,
                                //     target: {
                                //         ...e.target,
                                //         name: 'cityId',
                                //         value: value.cityId,
                                //     },
                                // });
                            }}
                            id="cityId"
                            getOptionSelected={(option) => {
                                return !!cityList?.find(
                                    (i: any) => i.id === option.id,
                                );
                            }}
                            style={{ width: '100%' }}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    fullWidth={true}
                                    id="cityId"
                                    name="cityId"
                                    label="City"
                                    variant="outlined"
                                    isRequired={true}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-city',
                                    }}
                                    error={
                                        formik.touched.cityId
                                            ? Boolean(formik.errors.cityId)
                                            : false
                                    }
                                    helperText={
                                        formik.touched.cityId &&
                                        formik.errors.cityId
                                    }
                                />
                            )}
                        />
                    </Grid>  */}
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CustomTextField
                            fullWidth={true}
                            label={
                                !!formik.values.state
                                    ? 'State'
                                    : 'State auto fill'
                            }
                            variant="outlined"
                            id="state"
                            name="state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.state
                                    ? Boolean(formik.errors.state)
                                    : false
                            }
                            helperText={
                                formik.touched.state && formik.errors.state
                            }
                            style={{
                                marginTop: '8px',
                                backgroundColor: '#ebebeb',
                                color: '#263238',
                                borderRadius: 8,
                            }}
                            labelStyle={{ color: '#263238' }}
                            isRequired={true}
                            disabled={true}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CustomTextField
                            fullWidth={true}
                            label="PIN Code"
                            variant="outlined"
                            id="pincode"
                            name="pincode"
                            value={formik.values.pincode ?? ''}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.pincode
                                    ? Boolean(formik.errors.pincode)
                                    : false
                            }
                            helperText={
                                formik.touched.pincode && formik.errors.pincode
                            }
                            style={{ marginTop: '8px' }}
                            isRequired={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            style={{
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                textAlign: 'left',
                                color: '#14112d',
                                marginTop: 12,
                            }}
                            variant="body2"
                        >
                            Emergency contact
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CustomTextField
                            fullWidth={true}
                            label="Name"
                            variant="outlined"
                            id="emergencyContactName"
                            name="emergencyContactName"
                            value={formik.values.emergencyContactName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.emergencyContactName
                                    ? Boolean(
                                          formik.errors.emergencyContactName,
                                      )
                                    : false
                            }
                            helperText={
                                formik.touched.emergencyContactName &&
                                formik.errors.emergencyContactName
                            }
                            style={{ marginBottom: '16px' }}
                            isRequired={true}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <CustomTextField
                            fullWidth={true}
                            label="Phone Number"
                            variant="outlined"
                            id="emergencyContactNumber"
                            name="emergencyContactNumber"
                            value={formik.values.emergencyContactNumber}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.emergencyContactNumber
                                    ? Boolean(
                                          formik.errors.emergencyContactNumber,
                                      )
                                    : false
                            }
                            helperText={
                                formik.touched.emergencyContactNumber &&
                                formik.errors.emergencyContactNumber
                            }
                            style={{ marginBottom: '16px' }}
                            isRequired={true}
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};
export { BasicInfoV2 };
