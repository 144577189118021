import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import Modal from './Modal';
import { CustomTextField } from '../Textfield';
import moment from 'moment';
import { FileUploader } from '../UI';
import { connect } from 'react-redux';
import {
    UploadStaffInfo,
    BulkUploadStaffs,
} from '../../store/actions/staffActionCreator';
import { showMessage } from '../../utils/helper';
import { useDebounce } from '../../hooks/useDebounce';

// const useStyles = makeStyles((theme) => ({
//     modal: {},
// }));

function UploadPayEarnModal({
    open,
    onClose,
    enumType = 'payment',
    data = {},
    dispatchUploadStaffInfo,
    dispatchBulkUploadStaffs,
    ...props
}) {
    const { debounce, isBouncing } = useDebounce();

    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [isSubmitDisabled, setSubmitDisabled] = useState(true);
    const handleClose = () => {
        // Setting form data to null before closing
        setState(null);
        setStateStatus(null);

        toggleModal(!isOpen);
        onClose && onClose();
    };
    const [state, setState] = useState({});
    const [stateStatus, setStateStatus] = useState({
        staffName: {},
        phoneNumber: {},
        vehicleNumber: {},
    });

    useEffect(() => {
        setSubmitDisabled(!state?.s3FileUrl);
    }, [state?.s3FileUrl]);

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            disabled={!!isSubmitDisabled || isBouncing || !state?.s3FileUrl}
            disableElevation
            onClick={() => {
                debounce(async () => {
                    if (!state?.s3FileUrl) {
                        showMessage('Please upload file', 'error');
                        return false;
                    }
                    toggleLoading(true);
                    setSubmitDisabled(true);
                    if (enumType === 'payment' || enumType === 'earning') {
                        dispatchUploadStaffInfo(
                            enumType,
                            { fileName: state?.s3FileUrl },
                            (response) => {
                                toggleLoading(false);
                                setSubmitDisabled(false);
                                console.log('s3FileUrl response', response);
                                if (response?.response?.s3FileUrl) {
                                    window.location.href =
                                        response?.response?.s3FileUrl;
                                }
                                handleClose();
                            },
                            (resp) => {
                                toggleLoading(false);
                                setSubmitDisabled(false);
                            },
                        );
                    } else {
                        dispatchBulkUploadStaffs(
                            { fileName: state?.s3FileUrl },
                            (response) => {
                                toggleLoading(false);
                                setSubmitDisabled(false);
                                console.log('s3FileUrl response', response);
                                if (response?.response?.s3FileUrl) {
                                    window.location.href =
                                        response?.response?.s3FileUrl;
                                }
                                handleClose();
                            },
                            (resp) => {
                                toggleLoading(false);
                                setSubmitDisabled(false);
                            },
                        );
                    }
                });
            }}
        >
            {enumType === 'payment'
                ? 'Upload Payment'
                : enumType === 'earning'
                ? 'Upload Earnings'
                : 'Upload Staffs'}
        </Button>,
    ];

    const handleInputChange = (value, key) => {
        setState((state) => ({
            ...state,
            [key]: value,
        }));

        setStateStatus(null); // formValidator(key, value);
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={
                enumType === 'payment'
                    ? 'Upload Payment'
                    : enumType === 'earning'
                    ? 'Upload Earnings'
                    : 'Upload Staffs'
            }
            subtitle={
                enumType === 'payment'
                    ? 'Upload Payments of your staff'
                    : enumType === 'earning'
                    ? 'Upload Earnings of your staff'
                    : 'Bulk Upload Staffs'
            }
            actions={actions}
            loading={loading}
        >
            <FileUploader
                label={'Upload Support Document'}
                placeholder={'Upload Support Document'}
                onFileUploaded={(url) => {
                    console.log(url);
                    handleInputChange(url, 's3FileUrl');
                }}
                isRequired={true}
            />
        </Modal>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
    dispatchUploadStaffInfo: (uploadType, data, onSuccess, onError) =>
        dispatch(UploadStaffInfo({ uploadType, ...data }, onSuccess, onError)),
    dispatchBulkUploadStaffs: (data, onSuccess, onError) =>
        dispatch(BulkUploadStaffs(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPayEarnModal);
