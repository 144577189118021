import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    makeStyles,
    Slide,
    useMediaQuery,
    useTheme,
    Typography,
    Avatar,
    Divider,
    DialogContent,
    DialogActions,
    Button,
    Tooltip,
} from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import {
    BikeIcon,
    CheckMarkGreen,
    CycleIcon,
    EComDeliveryIcon,
    FoodDeliveryIcon,
    GroceryDeliveryIcon,
    VanIcon,
} from '../../assets/svg';
import { Spinner } from '../UI';
import { connect } from 'react-redux';
import Tabbar from '../../components/Tabbar';
import {
    SignedContract,
    BasicInfo,
    BasicInfoV2,
    VerifyKyc,
    ViewHistory,
} from '../../pages/hire/index';
import moment from 'moment';
import {
    fetchAllLeads,
    fetchBasicLeadkyc,
    fetchBasicLeadProfile,
    fetchLeadEvents,
    updateLeadBasicInfo,
    updateLeadkycInfo,
} from '../../store/actions/leadActionCreator';
import VerifiedHireModal from './VerifiedHireModal';
import InCompleteHireModal from './InCompleteHireModal';
import OnHoldHireModal from './OnHoldHireModal';
import { showMessage } from '../../utils/helper';
import { GetLeadStatusTag } from '../../pages/hire/HireItem';
import { getWorkLocationZonesWithAssets } from '../../store/actions/settingsActionCreator';
import { validateForm } from '../../pages/hire/helper';
const useStyles = makeStyles((theme) => ({
    modal: {
        '& .modal-head': {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        '& .modal-title': {
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#14112d',
            marginLeft: '8px',
        },
        '& .modal-subtitle': {
            fontSize: '18px',
            fontWeight: 'normal',
            color: '#4caf50',
            marginLeft: '16px',
            fontFamily: 'Nunito',
            fontWeight: 'bold',
        },

        '& .modal-subtitle-icon': {
            height: '24px',
            width: '24px',
            marginLeft: '8px',
        },

        '& .modal-sub-heading': {
            marginLeft: '30px',
            display: 'flex',
            alignItems: 'center',
        },

        '& .modal-hub-sub-title': {
            fontSize: '18px',
            fontWeight: '600',
            color: '#14112d',
        },

        '& .modal-hub-title': {
            color: '#14112d',
            fontSize: '20px',
            fontWeight: 'bold',
        },

        '& .cancelButton': {
            color: theme.palette.text.primary,
        },
        '& .leaflet-container': {
            width: '100%',
            height: '510px',
            // borderRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
        '& .tabbar': {
            marginTop: '12px',
            backgroundColor: '#f7f7ff',
            height: '48px',
            '& .tab': {
                margin: '0px',
                color: '#a6aaaf',
                backgroundColor: '#f7f7ff',
                borderRadius: '0px',
                border: '0px',
                // padding: '16px 4px',
                '& .MuiTypography-body1': {
                    color: '#a6aaaf',
                    fontSize: '14px',
                },
                '& svg': {
                    '& g': {
                        fill: '#a6aaaf',
                    },
                    '& path': {
                        fill: '#a6aaaf',
                    },
                },
            },
            '& .active': {
                backgroundColor: '#f7f7ff',
                borderRadius: '0px',
                color: '#43419e',
                '& svg': {
                    '& g': {
                        fill: '#43419e',
                    },
                    '& path': {
                        fill: '#43419e',
                    },
                },
                '& .MuiTypography-body1': {
                    color: '#43419e !important',
                    fontSize: '14px',
                    borderBottom: '4px #43419e solid',
                    paddingBottom: '6px',
                },
            },
            '& .tab:first-child': {
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
            },
            '& .tab:last-child': {
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
            },
        },
    },
    iconButton: {
        padding: '0px',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeliveryIcon = {
    'Food Delivery': (
        <Tooltip title="Food Delivery" placement="top">
            <FoodDeliveryIcon
                width={20}
                height={20}
                style={{
                    marginLeft: '4px',
                    marginRight: '4px',
                }}
            />
        </Tooltip>
    ),
    'E-com Delivery': (
        <Tooltip title="ECom Delivery" placement="top">
            <EComDeliveryIcon
                width={20}
                height={20}
                style={{
                    marginLeft: '4px',
                    marginRight: '4px',
                }}
            />
        </Tooltip>
    ),
    'Grocery Delivery': (
        <Tooltip title="Grocery Delivery" placement="top">
            <GroceryDeliveryIcon
                width={20}
                height={20}
                style={{
                    marginLeft: '4px',
                    marginRight: '4px',
                }}
            />
        </Tooltip>
    ),
};

function LeadModal({
    open,
    onClose,
    maxWidth,
    isEdit,
    leadInfo,
    shouldShowActionBar = false,
    cityList,
    dispatchFetchBasicLeadProfile,
    dispatchFetchBasicLeadkyc,
    dispatchFetchLeadEvents,
    dispatchUpdateLeadBasicInfo,
    dispatchUpdateLeadkycInfo,
    dispatchFetchAllLeads,
    dispatchGetWorkLocationZonesWithAssets,
    ...props
}) {
    const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [loading, toggleLoading] = useState(false);
    const [disableUpdate, setDisableUpdate] = useState(false);
    const [tabIndex, settabIndex] = useState(0);
    const [submitPayload, setSubmitPayload] = useState({});

    const [verifiedHireModalOpen, setVerifiedHireModalOpen] =
        React.useState(false);
    const [incompleteModalOpen, setIncompleteModalOpen] = React.useState(false);
    const [onHoldModalOpen, setOnHoldModalOpen] = React.useState(false);

    const handleClose = () => {
        onClose && onClose();
    };

    const fetchAllHireInfo = async (payload = {}) => {
        await toggleLoading(true);
        dispatchFetchAllLeads(
            payload,
            (resp) => {
                toggleLoading(false);
                console.log('hire data', resp);
            },
            (resp) => {
                toggleLoading(false);
                console.log('hire data err', resp);
            },
        );
    };

    const tabs = [
        {
            label: 'Basic Info',
            content: (
                <BasicInfoV2
                    {...{
                        leadInfo,
                        isEdit,
                        cityList,
                        toggleLoading,
                        dispatchFetchBasicLeadProfile,
                        dispatchGetWorkLocationZonesWithAssets,
                        onValueChange: (val) => {
                            setSubmitPayload(val);
                        },
                    }}
                />
            ),
            disabled: false,
            isActive: true,
        },
        {
            label: 'Verify KYC',
            content: (
                <VerifyKyc
                    {...{
                        leadInfo,
                        isEdit,
                        toggleLoading,
                        dispatchFetchBasicLeadkyc,
                        onValueChange: (val) => {
                            setSubmitPayload(val);
                        },
                    }}
                />
            ),
            disabled: false,
            isActive: true,
        },
        {
            label: 'Signed Contract',
            content: (
                <SignedContract
                    {...{
                        leadInfo,
                        isEdit,
                        toggleLoading,
                        dispatchFetchBasicLeadkyc,
                    }}
                />
            ),
            disabled: false,
            isActive: isEdit,
        },
        {
            label: 'View History',
            content: (
                <ViewHistory
                    {...{
                        leadInfo,
                        isEdit,
                        toggleLoading,
                        dispatchFetchLeadEvents,
                    }}
                />
            ),
            disabled: false,
            isActive: isEdit,
        },
    ];
    return (
        <Spinner loading={loading}>
            <Dialog
                open={isOpen}
                Transition={Transition}
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.modal}
                fullWidth={true}
                maxWidth={maxWidth ?? 'md'}
            >
                <DialogTitle
                    id="alert-dialog-slide-title"
                    className={`modal-titlehead`}
                    style={{
                        top: 0,
                        overflow: 'hidden',
                        position: 'sticky',
                        background: 'white',
                        zIndex: 999,
                        padding: '16px 24px 0 24px',
                        borderBottom: '1px solid #eaedf3',
                    }}
                >
                    {!!isEdit ? (
                        <>
                            <Box className={`modal-head`}>
                                <div
                                    style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        display: 'flex',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            display: 'flex',
                                            width: '45%',
                                        }}
                                    >
                                        <IconButton
                                            aria-label="close"
                                            className={classes.iconButton}
                                            onClick={handleClose}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <Typography
                                            className={`modal-title`}
                                            variant="h6"
                                        >
                                            {leadInfo?.name}
                                        </Typography>
                                        <div
                                            className="hire-item"
                                            style={{
                                                marginLeft: 16,
                                            }}
                                        >
                                            <Typography
                                                style={{ width: '100%' }}
                                                className={GetLeadStatusTag(
                                                    leadInfo?.status,
                                                )}
                                            >
                                                {leadInfo.status?.toUpperCase() ??
                                                    '-'}
                                            </Typography>
                                        </div>
                                        {!!leadInfo?.kycVerified && (
                                            <>
                                                <Typography
                                                    className={`modal-subtitle`}
                                                    variant="body2"
                                                >
                                                    KYC Verfied
                                                </Typography>
                                                <CheckMarkGreen
                                                    className={
                                                        'modal-subtitle-icon'
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                    <IconButton
                                        aria-label="close"
                                        className={classes.iconButton}
                                        onClick={handleClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </Box>
                            <Box className="modal-sub-heading">
                                <Typography
                                    className={`modal-hub-title`}
                                    variant="h6"
                                    style={{ fontSize: '16px' }}
                                >
                                    {leadInfo?.city}
                                </Typography>
                                {!!leadInfo?.city && (
                                    <Typography
                                        className={`modal-hub-sub-title`}
                                        variant="h6"
                                        style={{
                                            marginLeft: '8px',
                                            marginRight: '8px',
                                            color: '#d0cfd5',
                                        }}
                                    >
                                        |
                                    </Typography>
                                )}
                                <Typography
                                    className={`modal-hub-sub-title`}
                                    variant="h6"
                                    style={{ fontSize: '16px' }}
                                >
                                    {leadInfo?.phoneNumber}
                                </Typography>
                                {!!leadInfo?.phoneNumber && (
                                    <Typography
                                        className={`modal-hub-sub-title`}
                                        variant="h6"
                                        style={{
                                            marginLeft: '8px',
                                            marginRight: '8px',
                                            color: '#d0cfd5',
                                        }}
                                    >
                                        |
                                    </Typography>
                                )}
                                <Typography
                                    className={`modal-hub-sub-title`}
                                    variant="h6"
                                    style={{ fontSize: '16px' }}
                                >
                                    Lead date:{' '}
                                    {moment(leadInfo?.dateEpochMillis).format(
                                        'DD-MMM-YYYY',
                                    )}
                                </Typography>
                                <Typography
                                    className={`modal-hub-sub-title`}
                                    variant="h6"
                                    style={{
                                        marginLeft: '8px',
                                        marginRight: '8px',
                                        color: '#d0cfd5',
                                    }}
                                >
                                    |
                                </Typography>

                                {!!leadInfo?.joiningFeeRequired && (
                                    <>
                                        <Typography
                                            className={`modal-hub-sub-title`}
                                            variant="h6"
                                            style={{
                                                fontSize: '16px',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            Joining Fee:{' '}
                                            {leadInfo?.joiningFeePaid ==
                                            true ? (
                                                <span
                                                    style={{
                                                        color: 'rgb(76, 175, 80)',
                                                    }}
                                                >
                                                    {' '}
                                                    Paid
                                                </span>
                                            ) : (
                                                <span
                                                    style={{
                                                        color: 'rgb(246, 17, 0)',
                                                    }}
                                                >
                                                    {' '}
                                                    Not Paid
                                                </span>
                                            )}
                                        </Typography>
                                        <Typography
                                            className={`modal-hub-sub-title`}
                                            variant="h6"
                                            style={{
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                                color: '#d0cfd5',
                                            }}
                                        >
                                            |
                                        </Typography>
                                    </>
                                )}

                                {(() => {
                                    switch (leadInfo?.vehicleType) {
                                        case 'Bike':
                                            return (
                                                <Tooltip
                                                    title="Bike"
                                                    placement="top"
                                                >
                                                    <BikeIcon
                                                        width={20}
                                                        height={20}
                                                    />
                                                </Tooltip>
                                            );
                                        case 'Van':
                                            return (
                                                <Tooltip
                                                    title="Van"
                                                    placement="top"
                                                >
                                                    <VanIcon
                                                        width={20}
                                                        height={20}
                                                    />
                                                </Tooltip>
                                            );
                                        case 'Cycle':
                                            return (
                                                <Tooltip
                                                    title="Cycle"
                                                    placement="top"
                                                >
                                                    <CycleIcon
                                                        width={20}
                                                        height={20}
                                                    />
                                                </Tooltip>
                                            );
                                        default:
                                            return <></>;
                                    }
                                })()}
                                <Typography
                                    className={`modal-hub-sub-title`}
                                    variant="h6"
                                    style={{
                                        marginLeft: '8px',
                                        marginRight: '8px',
                                        color: '#d0cfd5',
                                    }}
                                >
                                    |
                                </Typography>

                                {!!leadInfo?.deliveryPreferences?.length &&
                                    leadInfo?.deliveryPreferences?.map(
                                        (item) => <>{DeliveryIcon[item]}</>,
                                    )}
                            </Box>
                        </>
                    ) : (
                        <Box className={`modal-head`}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: '#14112d',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        aria-label="close"
                                        className={classes.iconButton}
                                        onClick={handleClose}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <Typography
                                        className={`modal-title`}
                                        variant="h6"
                                    >
                                        {'Add Lead'}
                                    </Typography>
                                </div>
                                <IconButton
                                    aria-label="close"
                                    className={classes.iconButton}
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </Box>
                    )}
                </DialogTitle>
                <DialogContent style={{ position: 'relative', height: '75vh' }}>
                    <Tabbar
                        tabs={tabs.filter((i) => !!i?.isActive)}
                        tabIndex={tabIndex}
                        onChange={(index, isModal) => {
                            settabIndex(index);
                        }}
                        style={{
                            height: '48px',
                            marginTop: 0,
                        }}
                        tabStyle={{
                            paddingBottom: '32px',
                        }}
                        textStyle={{
                            fontSize: '16px',
                        }}
                    />
                    {!!tabs[tabIndex].isActive &&
                        !tabs[tabIndex].disabled &&
                        tabs[tabIndex].content}
                </DialogContent>
                {!shouldShowActionBar && (
                    <DialogActions
                        style={{
                            bottom: 0,
                            overflow: 'hidden',
                            position: 'sticky',
                            background: 'white',
                            zIndex: 999,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className="btn-pos"
                            style={{
                                backgroundColor: '#e8e8ee',
                                color: '#43419e',
                                boxShadow: 'none',
                            }}
                            onClick={handleClose}
                            disableElevation
                        >
                            Cancel
                        </Button>
                        {!!isEdit && (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="btn-pos"
                                    style={
                                        !!disableUpdate ||
                                        leadInfo.status === 'Rejected' ||
                                        leadInfo.status === 'Converted' ||
                                        leadInfo.status === 'Verified'
                                            ? {}
                                            : {
                                                  boxShadow: 'none',
                                                  backgroundColor: '#4caf50',
                                              }
                                    }
                                    onClick={() => {
                                        setVerifiedHireModalOpen(true);
                                    }}
                                    disabled={
                                        !!disableUpdate ||
                                        leadInfo.status === 'Rejected' ||
                                        leadInfo.status === 'Converted' ||
                                        leadInfo.status === 'Verified'
                                    }
                                    disableElevation
                                >
                                    Mark Verified
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="btn-pos"
                                    style={
                                        !!disableUpdate ||
                                        leadInfo.status === 'Rejected' ||
                                        leadInfo.status === 'Converted' ||
                                        leadInfo.status ===
                                            'Marked Incomplete' ||
                                        leadInfo.status === 'Open'
                                            ? {}
                                            : {
                                                  boxShadow: 'none',
                                                  backgroundColor: '#f44336',
                                              }
                                    }
                                    onClick={() => {
                                        setIncompleteModalOpen(true);
                                    }}
                                    disabled={
                                        !!disableUpdate ||
                                        leadInfo.status === 'Converted' ||
                                        leadInfo.status ===
                                            'Marked Incomplete' ||
                                        leadInfo.status === 'Open'
                                    }
                                    disableElevation
                                >
                                    Mark Incomplete
                                </Button>
                            </>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            className="btn-pos"
                            style={
                                !!disableUpdate ||
                                tabIndex === 2 ||
                                tabIndex === 3 ||
                                leadInfo.status === 'Rejected' ||
                                leadInfo.status === 'Converted' 
                                //|| leadInfo.status === 'Verified'
                                    ? {}
                                    : {
                                          boxShadow: 'none',
                                          backgroundColor: '#ff9800',
                                      }
                            }
                            disabled={
                                !!disableUpdate ||
                                tabIndex === 2 ||
                                tabIndex === 3 ||
                                leadInfo.status === 'Rejected' ||
                                leadInfo.status === 'Converted' 
                                //|| leadInfo.status === 'Verified'
                            }
                            disableElevation
                            onClick={() => {
                                let methodCall =
                                    tabIndex === 0
                                        ? dispatchUpdateLeadBasicInfo
                                        : tabIndex === 1
                                        ? dispatchUpdateLeadkycInfo
                                        : () => {
                                              toggleLoading(false);
                                          };
                                let validation = tabIndex === 0 ? validateForm(submitPayload):true;
                                if (!!validation) {
                                    toggleLoading(true);
                                    methodCall(
                                        leadInfo.id,
                                        submitPayload,
                                        (resp) => {
                                            toggleLoading(false);
                                            showMessage(
                                                'Successfuly Lead Information Updated',
                                                'success',
                                            );
                                            handleClose();
                                        },
                                        (err) => {
                                            toggleLoading(false);
                                            showMessage(
                                                'Failed to update lead information',
                                                'error',
                                            );
                                        },
                                    );
                                }
                            }}
                        >
                            {isEdit ? 'Update Lead' : 'Add Lead'}
                        </Button>
                        {!!isEdit &&
                            !disableUpdate &&
                            leadInfo.status !== 'On Hold' &&
                            leadInfo.status !== 'Converted' &&
                            leadInfo.status !== 'Onboarded' && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="btn-pos"
                                    style={
                                        leadInfo.status === 'On Hold'
                                            ? {}
                                            : {
                                                  boxShadow: 'none',
                                                  backgroundColor: '#F4CE06',
                                              }
                                    }
                                    onClick={() => {
                                        setOnHoldModalOpen(true);
                                    }}
                                    disabled={leadInfo.status === 'On Hold'}
                                    disableElevation
                                >
                                    Mark On Hold
                                </Button>
                            )}
                    </DialogActions>
                )}
                {verifiedHireModalOpen && (
                    <VerifiedHireModal
                        open={verifiedHireModalOpen}
                        leadInfo={leadInfo}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setVerifiedHireModalOpen(false);
                            handleClose();
                        }}
                    />
                )}
                {incompleteModalOpen && (
                    <InCompleteHireModal
                        open={incompleteModalOpen}
                        leadInfo={leadInfo}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setIncompleteModalOpen(false);
                            handleClose();
                        }}
                    />
                )}
                {onHoldModalOpen && (
                    <OnHoldHireModal
                        open={onHoldModalOpen}
                        leadInfo={leadInfo}
                        onClose={() => {
                            console.log('Closed');
                            fetchAllHireInfo && fetchAllHireInfo({});
                            setOnHoldModalOpen(false);
                            handleClose();
                        }}
                    />
                )}
            </Dialog>
        </Spinner>
    );
}

const mapStateToProps = (state) => ({
    cityList: state.settings?.cities ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    dispatchFetchAllLeads: (payload, onSuccess, onError) =>
        dispatch(fetchAllLeads(payload, onSuccess, onError)),
    dispatchFetchBasicLeadProfile: (id, data, onSuccess, OnFailed) =>
        dispatch(fetchBasicLeadProfile(id, data, onSuccess, OnFailed)),
    dispatchFetchBasicLeadkyc: (id, data, onSuccess, OnFailed) =>
        dispatch(fetchBasicLeadkyc(id, data, onSuccess, OnFailed)),
    dispatchFetchLeadEvents: (id, data, onSuccess, OnFailed) =>
        dispatch(fetchLeadEvents(id, data, onSuccess, OnFailed)),
    dispatchUpdateLeadBasicInfo: (id, data, onSuccess, OnFailed) =>
        dispatch(updateLeadBasicInfo(id, data, onSuccess, OnFailed)),
    dispatchUpdateLeadkycInfo: (id, data, onSuccess, OnFailed) =>
        dispatch(updateLeadkycInfo(id, data, onSuccess, OnFailed)),
    dispatchGetWorkLocationZonesWithAssets: (data, onSuccess, onError) =>
        dispatch(getWorkLocationZonesWithAssets(data, onSuccess, onError)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadModal);
